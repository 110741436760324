/** @jsx jsx */
import { jsx, Grid, Input, Select } from 'theme-ui'
import { useField } from 'formik'

import ErrorField from './error-field'

import countries from '../../data/countries'

export default function CountrySelectField(props) {
  const [field] = useField(props)
  return (
    <Grid gap={5} sx={{ alignSelf: 'flex-start' }}>
      <Select
        {...field}
        {...props}
        sx={{ minHeight: 48, borderColor: 'black.10', paddingX: 's' }}
        selected="United Kingdom"
      >
        {countries.map((country) => (
          <option value={country.name}>{country.name}</option>
        ))}
      </Select>
      <ErrorField {...props} />
    </Grid>
  )
}
