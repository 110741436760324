import * as yup from 'yup' // for everything

export const orderSchema = yup.object().shape({
  organisationId: yup.string().required(),
  // contact info
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email().required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  // address info
  address1: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  county: yup.string().required('County is required'),
  postCode: yup.string().required('Post code is required'),
  country: yup.string().required('Country is required'),
  // card details
  cardNumber: yup.string(),
  cardExpiry: yup.string(),
  cardCvc: yup.string(),
})
