/** @jsx jsx */
import { jsx, Text } from 'theme-ui'

import { useField, ErrorMessage } from 'formik'

export default function ErrorField({ name }) {
  return (
    <Text variant="stnd.sub" sx={{ color: '#bb0000' }}>
      <ErrorMessage name={name} />
    </Text>
  )
}

export function CustomErrorField({ name }) {
  const [, meta] = useField(name)

  if (meta.error) {
    return (
      <Text variant="stnd.sub" sx={{ color: '#bb0000' }}>
        {meta.error}
      </Text>
    )
  }

  return null
}
