/** @jsx jsx */
import { jsx, Box, Flex, Text } from 'theme-ui'
import { useFormikContext, useField } from 'formik'
import { findIndex, propOr } from 'ramda'

import { daysDifference } from 'utils/dates'
import { toPounds } from 'utils'

import Subhead from './subhead'

function QuantityControl(props) {
  const { onClick, children } = props
  return (
    <Box
      sx={{
        border: 'solid 1px #eee',
        height: 24,
        width: 24,
        textAlign: 'center',
        lineHeight: '22px',
        borderRadius: 999,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}

export function ResourceCostStatic(props) {
  const { resource, index } = props

  const fieldName = `extras.${index}.quantity`

  const [field, meta, helpers] = useField(fieldName)

  const fieldValue = field.value ?? 1

  const handleDecrease = () => {
    if (fieldValue > 1) {
      helpers.setValue(fieldValue - 1)
    }
  }

  return (
    <Box>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Subhead>{resource.title}</Subhead>
      </Flex>

      <Flex sx={{ justifyContent: 'space-between' }}>
        <Flex sx={{ alignItems: 'center' }}>
          <Flex sx={{ marginRight: 10 }}>
            <QuantityControl onClick={handleDecrease}>-</QuantityControl>
            <Text variant="stnd.xs" sx={{ marginX: 10 }}>
              {fieldValue}
            </Text>
            <QuantityControl onClick={() => helpers.setValue(fieldValue + 1)}>
              +
            </QuantityControl>
          </Flex>
        </Flex>

        <Text variant="stnd.xs">{toPounds(resource.totalCost / 100)}</Text>
      </Flex>
    </Box>
  )
}

export default function ResourceCost(props) {
  const { resource } = props

  return (
    <Box>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Subhead>{resource.title}</Subhead>
      </Flex>

      <Flex sx={{ justifyContent: 'space-between' }}>
        <Text variant="stnd.xs">
          {daysDifference([resource.start, resource.end])} nights ×{' '}
          {toPounds(resource.blockCost / 100)}
        </Text>
        <Text variant="stnd.xs">{toPounds(resource.totalCost / 100)}</Text>
      </Flex>
    </Box>
  )
}
