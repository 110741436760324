/** @jsx jsx */
import { jsx, Box, Label, Checkbox } from 'theme-ui'
import { useField } from 'formik'

import Subhead from './subhead'
import { notEmptyOrNil } from 'utils/utils'

export default function BookingVariants(props) {
  const { availableVariants } = props

  const [field, meta, helpers] = useField('resources.0.variantId')

  const hasAvailableVariants = notEmptyOrNil(availableVariants)

  if (!hasAvailableVariants) {
    return null
  }

  return (
    <Box
      sx={{
        borderRadius: 10,
        overflow: 'hidden',
        border: 'solid 1px',
        borderColor: 'black.10',
        paddingBottom: 's',
      }}
    >
      <Box sx={{ paddingX: 's', paddingTop: 's' }}>
        <Subhead>Room Type</Subhead>
        {availableVariants.map((variant) => (
          <Label
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              fontSize: 'xxs',
            }}
          >
            <Checkbox
              {...field}
              onChange={() => helpers.setValue(variant.id)}
              sx={{ marginRight: 3 }}
              checked={field.value === variant.id}
            />
            {variant.name}
          </Label>
        ))}
      </Box>
    </Box>
  )
}
