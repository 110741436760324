/** @jsx jsx */
import { jsx, Grid, Input } from 'theme-ui'
import { useField } from 'formik'

import ErrorField from './error-field'

export default function TextField(props) {
  const [field] = useField(props)
  return (
    <Grid gap={5} sx={{ alignSelf: 'flex-start' }}>
      <Input {...field} {...props} />
      <ErrorField {...props} />
    </Grid>
  )
}
