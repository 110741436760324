/** @jsx jsx */
import { jsx, Grid, Box, Text } from 'theme-ui'
import Subhead from './subhead'

import { bookingDateFormat } from 'utils/dates'

export default function BookingDates({ start, end }) {
  return (
    <Grid columns={2} gap="s">
      <Box>
        <Subhead>Check In</Subhead>
        <Text variant="stnd.xs">{bookingDateFormat(start)}</Text>
      </Box>
      <Box>
        <Subhead>Check Out</Subhead>
        <Text variant="stnd.xs">{bookingDateFormat(end)}</Text>
      </Box>
    </Grid>
  )
}
