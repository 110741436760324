/** @jsx jsx */
import { jsx, Box, Checkbox, Label } from 'theme-ui'
import { useField } from 'formik'

export default function CheckboxField(props) {
  const [field] = useField(props)

  const { label, className } = props
  return (
    <Box className={className}>
      <Label
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          fontSize: 'xxs',
        }}
      >
        <Checkbox {...field} sx={{ marginRight: 3 }} />
        {label}
      </Label>
    </Box>
  )
}
