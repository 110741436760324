/** @jsx jsx */
import { jsx, Text } from 'theme-ui'

export default function Subhead({ children }) {
  return (
    <Text variant="subhead" sx={{ marginBottom: 5, color: 'black.50' }}>
      {children}
    </Text>
  )
}
